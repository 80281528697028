import { useState } from "react";
import "./App.css";
import Hills from "./components/Hills";

import Scene from "./components/Scene";
import House from "./components/House";
import Controls from "./components/Controls/Controls";
import UtilityPole from "./components/UtilityPole";
import Sky from "./components/Sky";
import MSLogo from "./components/MSLogo";
import PowerFlow from "./components/PowerFlow";
import Meter from "./components/Meter";
import KWHBank from "./components/KWHBank";
import LosingMoneyParticles from "./components/LosingMoneyParticles";

const INSTRUCTION_STEPS = [
  {},
  // {
  //   PowerFlow2: ["visible"],
  //   UtilityPole: ["visible"],
  //   Lights: ["visible"],
  // },
  // { Meter: ["visible", "forward"] },
  // { LosingMoneyParticles: ["visible"] },
  // {
  //   // LosingMoneyParticles: ["hidden"],
  //   // PowerFlow2: ["hidden"],
  //   // Meter: ["hidden"],
  //   // PanelMask: ["hidden"],
  //   Lights: ["visible"],
  // },
  { PanelMask: ["hidden"] },
  { Lights: ["visible"], PowerFlow1: ["visible"] },
  {
    UtilityPole: ["visible"],
    PowerFlow2: ["visible", "reverse"],
    Meter: ["visible", "reverse"],
  },
  {
    Bank: ["visible"],
  },
];
const reverseTags = (tags) => {
  return tags.map((v) => {
    switch (v) {
      case "hidden":
        return "visible";
      case "visible":
        return "hidden";
      case "forward":
        return "reverse";
      case "reverse":
        return "forward";
      case "daytime":
        return "nighttime";
      case "nighttime":
        return "daytime";
      default:
        console.log(v);
        return "";
    }
  });
};

function App() {
  const [direction, setDirection] = useState(1); // 1 for forward, -1 for reverse
  const [isDaytime, setIsDaytime] = useState(true);
  const [stepIndex, setStepIndex] = useState(0);

  const incrementStep = (val = 1) => {
    return setStepIndex((prev) => {
      const newStepIndex = prev + val;

      if (newStepIndex > prev) {
        if (direction === -1) {
          setDirection(1);
          return prev;
        }
        if (newStepIndex >= INSTRUCTION_STEPS?.length) return prev;
        return newStepIndex;
      }

      if (newStepIndex < prev) {
        if (direction === 1) {
          setDirection(-1);
          return prev;
        }
        if (newStepIndex < 0) return prev;
        return newStepIndex;
      }

      return newStepIndex;
    });
  };

  const currentStep = INSTRUCTION_STEPS[stepIndex];

  const getTags = (name) => {
    const tags = currentStep[name];
    if (!tags || tags.length < 1) return [];
    return direction > 0 ? tags : reverseTags(tags);
  };
  return (
    <div className="App">
      <Scene isDaytime={isDaytime}>
        {/* <p
          style={{
            color: "white",
            position: "fixed",
            top: "1em",
            left: "1em",
          }}
        >
          Step: {stepIndex}
        </p> */}
        <Sky isDaytime={isDaytime} tags={getTags("Sky")} />
        <Hills />
        <UtilityPole isDaytime={isDaytime} tags={getTags("UtilityPole")} />
        <Controls
          tags={getTags("Controls")}
          incrementStep={incrementStep}
          setIsDaytime={setIsDaytime}
          isDaytime={isDaytime}
          maxSteps={INSTRUCTION_STEPS?.length ?? 0}
          stepIndex={stepIndex}
        />
        <MSLogo isDaytime={isDaytime} />
        <Meter isDaytime={isDaytime} tags={getTags("Meter")} />
        <LosingMoneyParticles tags={getTags("LosingMoneyParticles")} />
        <KWHBank isDaytime={isDaytime} tags={getTags("Bank")} />
        <House
          isDaytime={isDaytime}
          houseTags={getTags("House")}
          panelTags={getTags("PanelMask")}
          lightsTags={getTags("Lights")}
        />
        <PowerFlow
          isDaytime={isDaytime}
          group1Tags={getTags("PowerFlow1")}
          group2Tags={getTags("PowerFlow2")}
        />
      </Scene>
    </div>
  );
}

export default App;
