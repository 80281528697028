import styled from "@emotion/styled";
import React, { useEffect, useRef, useState } from "react";

import meterImg from "../assets/meter.png";
import AnimatedNumber from "react-animated-numbers";
import VisibilityWrapper from "./VisibilityWrapper";

const numbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

function Meter({ isDaytime, tags }) {
  const [value, setValue] = useState(0);
  const wrapperRef = useRef();
  const [wrapperHeight, setWrapperHeight] = useState();
  const timeoutRef = useRef();
  const [reverse, setReverse] = useState(false);

  useEffect(() => {
    if (tags?.includes("reverse")) {
      setReverse(true);
      setValue(0);
    }
    if (tags?.includes("forward")) {
      setReverse(false);
      setValue(0);
    }
  }, [tags]);

  useEffect(() => {
    //Handles Ticking Time
    timeoutRef.current = setTimeout(
      () =>
        setValue(
          (prev) =>
            prev +
            (reverse && isDaytime ? -1 : 1) *
              (10 + Math.floor(Math.random() * 50))
        ),
      1500
    );
    return () => {
      timeoutRef.current && clearTimeout(timeoutRef.current);
    };
  }, [value]);

  useEffect(() => {
    setValue(0);
  }, [isDaytime]);

  useEffect(() => {
    function handleResize() {
      wrapperRef.current && setWrapperHeight(wrapperRef.current.offsetHeight);
    }
    // Attach the event listener to the window object
    window.addEventListener("resize", handleResize);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <VisibilityWrapper tags={tags}>
      <Wrapper
        ref={(node) => {
          wrapperRef.current = node;
          setWrapperHeight(node?.offsetHeight);
        }}
      >
        <img src={meterImg} alt="Net Meter" />
        <OdometerWrapper>
          <OdometerBackground
            fontSize={wrapperHeight ? wrapperHeight * 0.08 + "px" : 0}
          >
            {new Array(10).fill(null).map((_, i) => (
              <div key={i}>0</div>
            ))}
          </OdometerBackground>
          <AnimatedNumber
            animateToNumber={value}
            locale="en-US"
            fontStyle={{
              fontSize: wrapperHeight ? wrapperHeight * 0.08 + "px" : 0,
            }}
          />
        </OdometerWrapper>
      </Wrapper>
    </VisibilityWrapper>
  );
}

export default Meter;

const Wrapper = styled.div`
  position: absolute;
  z-index: 500;
  height: 19.63%;
  left: 44%;
  top: 13.61%;
  display: flex;
  justify-content: center;

  img {
    height: 100%;
  }
`;

const OdometerWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: flex-end;
  border-radius: 2px;
  color: white;
  top: 24%;
  margin: auto;
  min-width: 44px;
  width: 50%;
  height: 9.5%;
  overflow: hidden;
  border: 2px white inset;

  .animated-container {
    z-index: 200;
  }
  .animated-container > div {
    border-left: 1px solid rgba(255, 255, 255, 0.4);
    padding: 0px 1px;
    background-color: #212b31;
    flex: 1;
  }
  .animated-container > div:last-of-type {
    background-color: #eb5b5b;
    border-left: 2px solid rgba(255, 255, 255, 0.4);
  }

  &::after {
    position: absolute;
    content: " ";
    height: 400%;
    width: 100%;
    background: radial-gradient(white, grey 90%);
    opacity: 0.3;
    border-radius: 300px;
    left: 5%;
    z-index: 300;
  }
`;

const OdometerBackground = styled.div`
  position: absolute;
  content: " ";
  background-color: #212b31;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  flex-direction: row-reverse;
  overflow: hidden;

  div {
    color: white;
    font-size: ${({ fontSize }) => fontSize};
    background-color: #212b31;
    height: 100%;
    border-left: 1px solid rgba(255, 255, 255, 0.4);
    padding: 0px 1px;
  }

  div:first-of-type {
    border-left: 2px solid rgba(255, 255, 255, 0.4);
  }
`;
