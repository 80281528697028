import React, { useEffect, useState } from "react";
import houseImg from "../assets/house.png";
import houseWindowsImg from "../assets/house-lit-windows.png";
import panelMask from "../assets/panel-mask.png";
import styled from "@emotion/styled";
import { AnimatePresence, motion } from "framer-motion";
import VisibilityWrapper from "./VisibilityWrapper";

function House({ isDaytime, houseTags, panelTags, lightsTags }) {
  return (
    <Wrapper>
      <img
        src={houseImg}
        alt="A home with a Momentum Solar System installed"
        style={{ objectFit: "contain", height: "100%" }}
      />
      <VisibilityWrapper tags={lightsTags} delay={1}>
        <img
          // initial={{ opacity: 0 }}
          // animate={{ opacity: 0.9 }}
          // exit={{ opacity: 0 }}
          // transition={{ duration: 0.5, delay: 0.8 }}
          src={houseWindowsImg}
          alt="A home with a Momentum Solar System installed"
          style={{
            objectFit: "contain",
            height: "47%",
            position: "absolute",
            top: "29.4%",
            left: "17.2%",
          }}
        />
      </VisibilityWrapper>

      <VisibilityWrapper tags={panelTags} initiallyVisible={true}>
        {
          <img
            // initial={{ opacity: 0 }}
            // animate={{ opacity: 1 }}
            // exit={{ opacity: 0 }}
            // transition={{ duration: 0.5, delay: 0.8 }}
            src={panelMask}
            alt="A home with a Momentum Solar System installed"
            style={{
              objectFit: "stretch",
              height: "80%",
              width: "55%",
              position: "absolute",
              top: "12%",
              left: "10.4%",
            }}
          />
        }
      </VisibilityWrapper>
    </Wrapper>
  );
}

export default House;

const Wrapper = styled.div`
  position: absolute;
  height: 41.2%;
  bottom: 15%;
  left: 4.6%;
  z-index: 100;
`;
