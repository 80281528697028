import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import { ReactComponent as PowerPath } from "../assets/powerPath.svg";
import { motion } from "framer-motion";
import VisibilityWrapper from "./VisibilityWrapper";

function PowerFlow({ isDaytime = true, group1Tags, group2Tags }) {
  const [group1Visible, setGroup1Visible] = useState(false);

  const [group2Visible, setGroup2Visible] = useState(false);
  const [group2Reverse, setGroup2Reverse] = useState(false);

  useEffect(() => {
    if (group1Tags?.includes("visible")) setGroup1Visible(true);
    if (group1Tags?.includes("hidden")) setGroup1Visible(false);

    if (group2Tags?.includes("visible")) setGroup2Visible(true);
    if (group2Tags?.includes("hidden")) setGroup2Visible(false);
    if (group2Tags?.includes("forward")) setGroup2Reverse(false);
    if (group2Tags?.includes("reverse")) setGroup2Reverse(true);
  }, [group1Tags, group2Tags]);

  return (
    <Wrapper>
      <SVG
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 2799.423 1202.636"
        color={isDaytime ? "#F0C44B" : "#00ccff"}
      >
        <line
          style={{
            transitionDelay: group2Reverse && isDaytime ? "0s" : "0.5s",
          }}
          className={`${!group2Visible && "hidden"} ${
            group2Reverse && isDaytime && "reverse"
          }`}
          x1="457.911"
          y1="443.173"
          x2="1319.65"
          y2="0"
        />
        <line
          style={{
            transitionDelay: group2Reverse && isDaytime ? "0.5s" : "0s",
          }}
          className={`${!group2Visible && "hidden"} ${
            group2Reverse && isDaytime && "reverse"
          }`}
          x1="1789.485"
          y1="0"
          x2="2570.177"
          y2="440.797"
        />
        <path
          className={`${(!group1Visible || !isDaytime) && "hidden"} reverse`}
          d="m164.264,632.914h-27.106s-11.294-3.388-24.847,11.294-103.906,99.388-103.906,99.388c0,0-7.906,4.518-7.906,19.2v347.858"
        />
        <line
          className={`${!group1Visible && "hidden"}`}
          x1="103.276"
          y1="1202.136"
          x2="103.276"
          y2="1144.537"
        />
        <polyline
          className={`${!group1Visible && "hidden"}`}
          points="343.84 1144.537 343.84 1202.136 40.029 1202.136"
        />
      </SVG>
    </Wrapper>
  );
}

export default PowerFlow;

const Wrapper = styled.div`
  position: absolute;
  z-index: 1000;
  height: 100%;
  width: 100%;
`;

const SVG = styled.svg`
  position: absolute;
  top: 25.6%;
  left: 9%;
  width: 71.8%;

  path,
  polyline,
  line {
    fill: none;
    stroke: ${(props) => props.color};
    stroke-width: 20;
    stroke-dasharray: 20;
    transition: 0.5s;
    animation: dash 5s linear infinite;
    -webkit-animation: dash 5s linear infinite;
  }

  @-webkit-keyframes dash {
    to {
      stroke-dashoffset: 1000;
    }
  }

  .reverse {
    animation: dash 5s linear infinite reverse;
    -webkit-animation: dash 5s linear infinite reverse;
  }

  .hidden {
    opacity: 0;
  }
`;
