import React from "react";
// import { ReactComponent as HillsImg } from "../assets/field.svg";
import hillsImg from "../assets/hills.png";
function Hills() {
  return (
    <img
      src={hillsImg}
      alt="Rolling Green Hills"
      style={{
        width: "100%",
        position: "absolute",
        bottom: "-10%",
      }}
    />
  );
}

export default Hills;
