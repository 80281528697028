import styled from "@emotion/styled";
import React, { useEffect, useRef, useState } from "react";

const N = 300;
const starClasses = 3;

function Stars({ isDaytime }) {
  const wrapperRef = useRef();
  const [starCoords, setStarCoords] = useState([]);

  useEffect(() => {
    if (wrapperRef.current)
      setStarCoords(
        new Array(N).fill(null).map((_) => ({
          className: randomStarClass(),
          style: randomCoordsStyle(),
        }))
      );
  }, [wrapperRef]);

  function randomStarClass() {
    return "star-" + (Math.floor(Math.random() * starClasses) + 1);
  }
  function randomCoordsStyle() {
    if (!wrapperRef.current) return { backgroundColor: "rgba(255,0,0,1)" };
    return {
      top: Math.random() * wrapperRef.current.offsetHeight,
      left: Math.random() * wrapperRef.current.offsetWidth,
    };
  }

  return (
    <Wrapper style={{ opacity: isDaytime ? 0 : 1 }} ref={wrapperRef}>
      <div className="twinkle-1">
        {new Array(Math.floor(starCoords.length / 3)).fill(null).map((_, i) => (
          <div
            className={`${starCoords[i].className}`}
            style={starCoords[i].style}
            key={i}
          />
        ))}
      </div>
      <div className="twinkle-2">
        {new Array(Math.floor(starCoords.length / 3)).fill(null).map((_, i) => (
          <div
            className={`${starCoords[i + starCoords.length / 3].className}`}
            style={starCoords[i + starCoords.length / 3].style}
            key={i}
          />
        ))}
      </div>
      <div className="twinkle-3">
        {new Array(Math.floor(starCoords.length / 3)).fill(null).map((_, i) => (
          <div
            className={`${
              starCoords[i + (starCoords.length / 3) * 2].className
            }`}
            style={starCoords[i + (starCoords.length / 3) * 2].style}
            key={i}
          />
        ))}
      </div>
    </Wrapper>
  );
}

export default Stars;

const Wrapper = styled.div`
  position: absolute;
  transition: 1s;

  height: 75%;
  width: 100vw;

  .star-1 {
    position: absolute;
    height: 2px;
    width: 2px;
    border-radius: 100px;
    background-color: white;
  }

  .star-2 {
    position: absolute;
    height: 3px;
    width: 3px;
    border-radius: 100px;
    background-color: white;
  }

  .star-3 {
    position: absolute;
    height: 4px;
    width: 4px;
    border-radius: 100px;
    background-color: white;
  }

  .twinkle-1 {
    animation: twinkle 1s infinite ease-in-out alternate;
  }

  .twinkle-2 {
    animation: twinkle 1.3s 0.4s infinite ease-in-out alternate;
  }

  .twinkle-3 {
    animation: twinkle 1.7s 0.6s infinite ease-in-out alternate;
  }

  @keyframes twinkle {
    from {
      opacity: 0.2;
    }
    to {
      opacity: 1;
    }
  }
`;
