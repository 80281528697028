import React from "react";

import sunImg from "../assets/sun.png";
import sunCenterImg from "../assets/sun-center.png";
import sunRaysImg from "../assets/sun-rays.png";
import moonImg from "../assets/moon.png";

import { AnimatePresence, motion } from "framer-motion";
import styled from "@emotion/styled";

const transition = {
  type: "spring",
  delay: 0.2,
  damping: 20,
  stiffness: 200,
};

function SunMoon({ isDaytime = true }) {
  return (
    <AnimatePresence>
      <MotionWrapper
        initial={{ rotate: -30 }}
        animate={{ rotate: 0 }}
        exit={{ rotate: 80 }}
        transition={transition}
        key={isDaytime ? "day" : "night"}
      >
        <MotionSunMoonWrapper>
          {isDaytime ? (
            <>
              <SunMoonImg
                animate={{ rotate: 360 }}
                transition={{ ease: "linear", duration: 90, repeat: Infinity }}
                src={sunRaysImg}
                alt="The Sun"
              />
              <SunMoonImg
                src={sunCenterImg}
                style={{
                  height: "42%",

                  objectFit: "contain",
                }}
                alt="The Sun"
              />
            </>
          ) : (
            <SunMoonImg
              src={moonImg}
              alt="The Moon"
              style={{ height: "65%" }}
            />
          )}
        </MotionSunMoonWrapper>
      </MotionWrapper>
    </AnimatePresence>
  );
}

const MotionWrapper = styled(motion.div)`
  position: absolute;
  height: 95.7%;
  left: 14.9%;
  top: 4.7%;
  transform-origin: 50% 100%;
`;

const MotionSunMoonWrapper = styled.div`
  position: relative;
  height: 33.33%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const SunMoonImg = styled(motion.img)`
  position: absolute;
  height: 100%;
`;

export default SunMoon;
