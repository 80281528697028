import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";

function VisibilityWrapper({
  tags,
  children,
  initiallyVisible = false,
  delay = 0,
}) {
  const [visible, setVisible] = useState(initiallyVisible);

  useEffect(() => {
    if (tags?.includes("visible")) setVisible(true);
    if (tags?.includes("hidden")) setVisible(false);
  }, [tags]);

  return (
    <Wrapper delay={!visible ? 0 : delay} style={{ opacity: visible ? 1 : 0 }}>
      {children}
    </Wrapper>
  );
}

export default VisibilityWrapper;

const Wrapper = styled.div`
  transition: 0.3s;
  transition-delay: ${(props) => props.delay + "s"};
`;
