import React from "react";
import { ReactComponent as CloudSVG } from "../assets/cloud.svg";
import styled from "@emotion/styled";
import { motion } from "framer-motion";

const SPEED = 1;

const transition = { repeat: Infinity, duration: 120 / SPEED };
const initial = { x: "-20%" };
const animate = { x: "120%" };

function Clouds({ isDaytime }) {
  const lightCloudColor = isDaytime ? "white" : "#999";
  const darkCloudColor = isDaytime ? "#efefef" : "#777";
  return (
    <Wrapper>
      <MotionWrapper
        initial={initial}
        animate={animate}
        transition={transition}
      >
        <Cloud height="50%" width="18%" color={darkCloudColor} y="80%" x="1%" />
        <Cloud height="50%" width="12%" y="87%" color={lightCloudColor} />
      </MotionWrapper>
      <MotionWrapper
        initial={initial}
        animate={animate}
        transition={{
          ...transition,
          delay: 33 / SPEED,
        }}
      >
        <Cloud
          height="50%"
          width="10%"
          y="60%"
          x="-10%"
          color={lightCloudColor}
        />
        <Cloud height="50%" width="12%" y="69%" color={lightCloudColor} />
      </MotionWrapper>

      <MotionWrapper
        initial={initial}
        animate={animate}
        transition={{
          ...transition,
          delay: 79 / SPEED,
        }}
      >
        <Cloud
          height="50%"
          width="10%"
          color={darkCloudColor}
          x="-15%"
          y="72%"
        />
        <Cloud
          height="50%"
          width="10%"
          x="-13%"
          y="74%"
          color={lightCloudColor}
        />
      </MotionWrapper>
    </Wrapper>
  );
}

export default Clouds;

const Wrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
`;

const MotionWrapper = styled(motion.div)`
  position: absolute;
  color: ${(props) => props.color ?? "white"};
  display: flex;
  height: 100%;
  height: 30%;
  width: 100%;
`;

const Cloud = styled(CloudSVG)`
  position: absolute;
  z-index: 200;
  top: ${(props) => props.y ?? 0};
  left: ${(props) => props.x ?? 0};
  transition: 1s;
`;
