import styled from "@emotion/styled";
import React from "react";
import VisibilityWrapper from "./VisibilityWrapper";

function LosingMoneyParticles({ tags }) {
  return (
    <VisibilityWrapper tags={tags}>
      <Wrapper>
        <Particle xOffset={10} />
        <Particle xOffset={-30} delay={0.5} />
        <Particle xOffset={-10} delay={1} />
        <Particle xOffset={30} delay={1.5} />
      </Wrapper>
    </VisibilityWrapper>
  );
}

export default LosingMoneyParticles;

const Particle = ({ xOffset = 0, delay = 0 }) => {
  return (
    <ParticleWrapper style={{ animationDelay: delay + "s" }}>
      <p
        style={{ transform: `translateX(${xOffset}px)`, whiteSpace: "nowrap" }}
      >
        -$
      </p>
    </ParticleWrapper>
  );
};

const Wrapper = styled.div`
  position: absolute;
  top: 32%;
  left: 48%;
`;

const ParticleWrapper = styled.div`
  position: absolute;
  font-size: 24px;
  color: #eb5b5b;
  font-weight: bold;
  animation: drift 2s infinite ease-out;

  @keyframes drift {
    0% {
      transform: translateY(0px);
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    100% {
      transform: translateY(50px);
      opacity: 0;
    }
  }
`;
