import React from "react";

function Scene({ children }) {
  return (
    <div
      style={{
        width: "100%",
        height: "calc(min(100vw, 1920px) * 0.5625)",
        maxWidth: "1920px",
        maxHeight: "1080px",
        overflow: "hidden",
        position: "relative",
        background: "white",
      }}
    >
      {children}
    </div>
  );
}

export default Scene;
