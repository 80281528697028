import { AnimatePresence, motion } from "framer-motion";
import msLogoDayImg from "../assets/msLogo-fullColor.png";
import msLogoNightImg from "../assets/msLogo-white.png";
import styled from "@emotion/styled";

function MSLogo({ isDaytime }) {
  return (
    <AnimatePresence>
      <MotionImg
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        key={isDaytime ? "mslogo-day" : "mslogo-night"}
        src={isDaytime ? msLogoDayImg : msLogoNightImg}
        alt="Momentum Solar"
        transition={{ duration: 1 }}
      />
    </AnimatePresence>
  );
}

export default MSLogo;

const MotionImg = styled(motion.img)`
  position: absolute;
  height: 6.94%;
  top: 6%;
  right: calc(3em + 1%);
`;
