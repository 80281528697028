import styled from "@emotion/styled";
import React from "react";

function Dots({ current = 0, max = 0 }) {
  return (
    <Wrapper>
      {new Array(max).fill(null).map((_, i) => (
        <Dot filled={i <= current} key={"dot" + i} />
      ))}
    </Wrapper>
  );
}

export default Dots;

const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  bottom: 1em;
  z-index: 1000;
  height: 1em;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
`;

const Dot = styled.div`
  min-height: 0.5em;
  min-width: 0.5em;
  background-color: ${({ filled }) => (filled ? "lightgrey" : "transparent")};
  border-radius: 100px;
  border: 1px solid white;
`;
