import styled from "@emotion/styled";
import React from "react";

import { ReactComponent as SunMoonIcon } from "../../assets/day-and-night-icon.svg";

function NightDayToggleSwitch({ isDaytime, setIsDaytime }) {
  return (
    <Wrapper
      style={{ background: isDaytime ? "#F0C44B" : "#00ccff" }}
      onClick={() => setIsDaytime((prev) => !prev)}
    >
      <SwitchButton className={isDaytime ? "" : "toggled"}>
        <SunMoonIcon
          height="2em"
          width="2em"
          style={{ position: "absolute" }}
        />
      </SwitchButton>
    </Wrapper>
  );
}

export default NightDayToggleSwitch;

const Wrapper = styled.button`
  position: absolute;
  top: 1em;
  left: 5em;
  width: 5em;
  background-color: red;
  border-radius: 100px;
  border: none;
  padding: 0;
`;

const SwitchButton = styled.div`
  border-radius: 100px;
  aspect-ratio: 1;
  height: 3em;
  width: 3em;
  transition: 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;

  &.toggled {
    transform: translateX(2em);
  }
`;
