import { ReactComponent as UtilityPoleSVG } from "../assets/utilityPole.svg";
import styled from "@emotion/styled";
import VisibilityWrapper from "./VisibilityWrapper";

function UtilityPole({ isDaytime, tags }) {
  //TODO: Put back delay of 1.2
  return (
    <VisibilityWrapper tags={tags} delay={0 ?? 1.2}>
      <Wrapper
        initial={{ opacity: 1 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 1 }}
        transition={{ duration: 1 }}
        style={{ color: isDaytime }}
      >
        <UtilityPoleSVG
          height="100%"
          style={{
            color: isDaytime ? "#4c4747" : "#bfbebe",
            transition: "1s",
          }}
        />
      </Wrapper>
    </VisibilityWrapper>
  );
}

export default UtilityPole;

const Wrapper = styled.div`
  position: absolute;
  height: 52%;
  bottom: 10.5%;
  z-index: 500;
  right: 4em;
  width: 20%;
`;
