import { motion } from "framer-motion";
import React from "react";

import styled from "@emotion/styled";
import SunMoon from "./SunMoon";
import Clouds from "./Clouds";
import Stars from "./Stars";

const variants = {
  day: { background: "#CBEDF0" },
  night: { background: "#394243" },
};

function Sky({ isDaytime = true }) {
  return (
    <MotionWrapper
      animate={isDaytime ? "day" : "night"}
      transition={{ duration: 1 }}
      variants={variants}
    >
      <Stars isDaytime={isDaytime} />
      <SunMoon isDaytime={isDaytime} />
      <Clouds isDaytime={isDaytime} />
    </MotionWrapper>
  );
}
export default Sky;

const MotionWrapper = styled(motion.div)`
  position: absolute;
  height: 100%;
  width: 100%;
`;
