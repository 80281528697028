import styled from "@emotion/styled";
import { ReactComponent as LightningBolt } from "../assets/lightning-bolt.svg";
import { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import VisibilityWrapper from "./VisibilityWrapper";

const MAX_BARS = 5;

function KWHBank({ isDaytime, tags }) {
  const [count, setCount] = useState(0);
  const timeoutRef = useRef();

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (tags?.includes("visible")) {
      if (!visible) setCount(isDaytime ? 0 : MAX_BARS);
      setVisible(true);
    }
    if (tags?.includes("hidden")) {
      if (visible) setCount(isDaytime ? 0 : MAX_BARS);
      setVisible(false);
    }
  }, [tags]);

  useEffect(() => {
    if (!visible) return;
    if (isDaytime && count < 0) return setCount(0);
    if (!isDaytime && count >= MAX_BARS) return setCount(MAX_BARS - 1);
    if (count >= MAX_BARS) return;
    if (count < 0) return;

    timeoutRef.current = setTimeout(() => {
      setCount((prev) => prev + (isDaytime ? 1 : -1));
    }, 1000);
    return () => {
      timeoutRef.current && clearTimeout(timeoutRef.current);
    };
  }, [count, isDaytime, visible]);

  return (
    <VisibilityWrapper tags={tags}>
      <Wrapper>
        <Text style={{ color: isDaytime ? "#4c4747" : "#bfbebe" }}>
          Banked Kilowatt Hours
        </Text>

        <BarsWrapper color={isDaytime ? "#4c4747" : "#bfbebe"}>
          {new Array(MAX_BARS)
            .fill(null)
            .map((_, i) => {
              const style = {
                background: isDaytime ? "#aade03" : "#b4e700",
                transition: "1s",
              };
              return i <= count ? (
                <Bar key={i} style={style}>
                  kWh
                </Bar>
              ) : (
                <Bar style={{ opacity: 0, ...style }} key={i}>
                  kWh
                </Bar>
              );
            })
            .reverse()}
        </BarsWrapper>
        {/* <Lightning /> */}
      </Wrapper>
    </VisibilityWrapper>
  );
}

export default KWHBank;

const Wrapper = styled.div`
  position: absolute;
  top: 18%;
  right: 4em;
  height: 16%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20%;
`;

const Text = styled.p`
  position: absolute;
  top: -3em;
  font-size: 16px;
  font-weight: bold;
  white-space: nowrap;
  text-align: center;
`;

const BarsWrapper = styled.div`
  /* height: 100%; */
  width: 40%;

  align-self: center;
  /* 
  border-radius: 4px;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;*/

  /* display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr; */
  /* grid-auto-flow: column; */
  border-radius: 8px;

  border: 2px dashed ${({ color }) => color};

  /* -moz-transform: scaleY(-1);
  -webkit-transform: scaleY(-1);
  -o-transform: scaleY(-1);
  transform: scaleY(-1); */
`;

const Bar = styled(motion.div)`
  z-index: 2000;
  /* width: 100%; */
  text-align: center;
  color: white;
  font-weight: bold;
  font-size: 12px;
  padding: 1px;
  margin: 1px;

  &:first-of-type {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }

  &:last-of-type {
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
  }
`;

const Lightning = styled(LightningBolt)`
  position: absolute;
  margin: auto;

  height: 50%;
  width: 25%;
  display: flex;
  aspect-ratio: 1;
  border-radius: 100px;
  opacity: 0.6;
  z-index: 1200;
`;
