import React from "react";
import { ReactComponent as ChevronLeft } from "../../assets/chevron-left.svg";
import styled from "@emotion/styled";
import NightDayToggleSwitch from "./NightDayToggleSwitch";
import Dots from "./Dots";

function Controls({
  stepIndex,
  incrementStep,
  isDaytime,
  setIsDaytime,
  maxSteps,
}) {
  return (
    <Wrapper>
      <Button onClick={() => incrementStep(-1)}>
        <ChevronLeft width="100%" height="2em" />
      </Button>
      <Button onClick={() => incrementStep(1)}>
        <ChevronRight width="100%" height="2em" />
      </Button>
      <NightDayToggleSwitch isDaytime={isDaytime} setIsDaytime={setIsDaytime} />
      <Dots current={stepIndex} max={maxSteps} />
    </Wrapper>
  );
}

export default Controls;

const Wrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 5000;
`;

const Button = styled.button`
  background: rgba(0, 0, 0, 0.2);
  z-index: 5000;
  color: white;
  width: 4em;
  border: none;
  height: 100%;
  transform: scaleY(2);
`;

const ChevronRight = styled(ChevronLeft)`
  transform: rotate(180deg);
`;
